<template>
    <div class="vx-col md:w-1/1 w-full">
        <vx-card title="Promotion V2">
            <formPromotion v-if="isForm" :selectedPromotion="selectedPromotion" @formClose="formClose" :MonitoringType="'approval'"></formPromotion>
            <monitoring v-show="!isForm" @clickDetail="clickDetail" :MonitoringType="'approval'"></monitoring>
        </vx-card>
    </div>
</template>

<script>
import formPromotion from "../form/form.vue";
import monitoring from "../monitoring/index.vue";
import matrix from "../matrix/index.vue";
import set from "../set/index.vue";

export default {
    components: {
        formPromotion,
        monitoring,
        matrix,
        set,
    },
    mounted() {
        // this.getOptionTerritory();
        // console.log("mounted", this.selectedPromotion);
    },
    data: () => ({
        tabColor:['primary', 'rgb(62, 201, 214)', 'warning', 'rgb(33, 187, 55)'],
        tabIndex: 0,
        territory: {},
        optionTerritory: [{}],
        salesChannel: {},
        optionSalesChannel:[{}],
        customerCategory: {},
        optionCustomerCategory:[{}],
        colorx: "success",
        selectedPromotion: {},
        isForm:false
    }),
    watch: {
        // territory: function(val) {
        //     console.log(val.id);
        //     console.log(val.code);
        // }
    },
    methods: {
        getOptionTerritory() {
            this.$http.get("/api/v1/master/territory").then(resp => {
                this.optionTerritory = resp.data.records;
                this.territory = resp.data.records[0];
            });
        },
        clickDetail(promotion){
            console.log("check Detail",promotion)
            this.selectedPromotion = promotion;
            this.isForm = true
        },
        formClose(){
            this.selectedPromotion={}
            this.isForm = false
        }
    }
};
</script>

<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style>